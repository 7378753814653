import { createAction } from 'redux-actions';
import {
  GET_ACCOUNT_CONFIG_FAILURE,
  GET_ACCOUNT_CONFIG_SUCCESS,
  GET_ACCOUNT_CONFIG_LOADING,
} from '../action-types/account';

import { getAccountConfigs as getAccountConfigsService } from '../../services/AccountServices';

const getAccountConfigLoading = createAction(GET_ACCOUNT_CONFIG_LOADING);
const getAccountConfigSuccess = createAction(GET_ACCOUNT_CONFIG_SUCCESS);
const getAccountConfigFaulure = createAction(GET_ACCOUNT_CONFIG_FAILURE);

export const getAccountConfig = () => async (dispatch) => {
  dispatch(getAccountConfigLoading());

  try {
    const { data } = await getAccountConfigsService();
    dispatch(getAccountConfigSuccess(data));
  } catch (error) {
    dispatch(getAccountConfigFaulure());
  }
};
