import { createAction } from 'redux-actions';
import {
  USER_LOGIN_SET_STORAGE_SUCCESS,
  USER_LOGIN_SET_STORAGE_FAILURE,
  USER_LOGIN_SET_STORAGE_LOADING,
  LOGIN_WITH_CODE_FAILURE,
  LOGIN_WITH_CODE_LOADING,
  LOGIN_WITH_CODE_SUCCESS,
} from '../action-types/user';

import { userLoginWithCode as userLoginWithCodeService } from '../../services/UserServices';

import { Constants } from '../../Constants';
import { ToastContainer, toast } from 'react-toastify';
var CryptoJS = require('crypto-js');

const userLoginSetStorageLoading = createAction(USER_LOGIN_SET_STORAGE_LOADING);
const userLoginSetStorage = createAction(USER_LOGIN_SET_STORAGE_SUCCESS);
const userLoginSetStorageFaulure = createAction(USER_LOGIN_SET_STORAGE_FAILURE);

const loginWithCodeLoading = createAction(LOGIN_WITH_CODE_LOADING);
const loginWithCodeSuccess = createAction(LOGIN_WITH_CODE_SUCCESS);
const loginWithCodeFailure = createAction(LOGIN_WITH_CODE_FAILURE);

export const gerUserLogin = () => async (dispatch) => {
  dispatch(userLoginSetStorageLoading());
  try {
    const data = await getLocalStorage();
    dispatch(userLoginSetStorage(data));
  } catch (error) {
    dispatch(userLoginSetStorageFaulure());
  }
};

const getLocalStorage = () => {
  let result = localStorage.getItem(Constants.HASH_USER_SESSION);
  if (null != result) {
    let bytes = CryptoJS.AES.decrypt(result, Constants.KEY_USER_SESSION);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plaintext);
  } else {
    return null;
  }
};

export const loginWithCode = (code) => async (dispatch) => {
  dispatch(loginWithCodeLoading());
  toast.configure();
  try {
    const { data } = await userLoginWithCodeService(code);
    setLocalStorage(data.data);
    dispatch(loginWithCodeSuccess(data.data));
    window.location.reload();
  } catch (error) {
    toast.error('Código de ingreso no válido', {
      position: toast.POSITION.BOTTOM_CENTER,
      pauseOnHover: false,
      duration: 10000,
    });
    dispatch(loginWithCodeFailure());
  }
};

export const logout = () => async (dispatch) => {
  dispatch(loginWithCodeLoading());
  try {
    removeLocalStorage();
    dispatch(loginWithCodeSuccess(null));
  } catch (error) {
    dispatch(loginWithCodeFailure());
  }
};

const setLocalStorage = (data) => {
  localStorage.setItem(
    Constants.HASH_USER_SESSION,
    CryptoJS.AES.encrypt(JSON.stringify(data), Constants.KEY_USER_SESSION)
  );
};

const removeLocalStorage = () => {
  localStorage.removeItem(Constants.HASH_USER_SESSION);
};
