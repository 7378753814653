import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { getPageFAQ, getPageSell } from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { connect } from 'react-redux';
import TitleBox from '../../ui-components/title-box';

class Sell extends Component {
    state = {
        head: '',
        items: [],
        loading: false,
        title: 'Vender',
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        try {
            await this.setState({ loading: true });
            let response = await getPageSell();
            let { head, items } = response.data.data;
            await this.setState({
                head: head.dynamic_seccion,
                items: items,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <section className="faq-area ptb-60">
                    <div className="container section sell">
                        <TitleBox title='Vender' />
                        <div dangerouslySetInnerHTML={{ __html: this.state.head }} className="description-inner-html" />
                        <div className="faq-accordion">
                            <ul className="accordion">
                                <Accordion allowMultipleExpanded={true}>
                                    {
                                        this.state.items.map(item =>
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {item.title}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div dangerouslySetInnerHTML={{ __html: item.description }} />
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        )
                                    }
                                </Accordion>
                            </ul>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
});

export default connect(mapStateToProps, null)(Sell);