import React from 'react';
import './styles.scss';

const TitleBox = ({ title, backgroundDark }) => {
  return (
    <div className={`title-box ${backgroundDark ? 'light' : ''}`}
      style={window.socket_io_customer === 'luisina_veiga' ? { width: '100%' } : {}}>
      <div className='title'>{title}</div>
      <div className='border-left'></div>
      <div className='border-right'></div>
    </div>
  );
};

export default TitleBox;
