import { createAction } from 'redux-actions';
import {
  GET_ALL_AUCTION_FAILURE,
  GET_ALL_AUCTION_LOADING,
  GET_ALL_AUCTION_SUCCESS,
} from '../action-types/auction';

import { getAllAuctions as getAllAuctionsService } from '../../services/AuctionServices';

const getAllAutionLoading = createAction(GET_ALL_AUCTION_LOADING);
const getAllAutionSuccess = createAction(GET_ALL_AUCTION_SUCCESS);
const getAllAutionFaulure = createAction(GET_ALL_AUCTION_FAILURE);

export const getAllAuctions = () => async (dispatch) => {
  dispatch(getAllAutionLoading());

  try {
    const { data } = await getAllAuctionsService();
    dispatch(getAllAutionSuccess(data));
  } catch (error) {
    dispatch(getAllAutionFaulure());
  }
};
