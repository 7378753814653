import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import HomePage from './pages/home/index';
import AuctionDetails from './pages/auctions/AuctionDetails';
import LotDetails from './pages/lots/LotDetails';
import Contact from './pages/Contact';
import Login from './pages/Login';
import FAQ from './pages/dynamics/FAQ';
import Register from './pages/register/Register';
import { connect } from 'react-redux';
import { userIsLoggedAction } from './redux/actions/UserActions';
import MyAccount from './pages/account/MyAccount';
import { Constants } from './Constants';
import WayToPay from './pages/dynamics/WayToPay';
import Buy from './pages/dynamics/Buy';
import Sell from './pages/dynamics/Sell';
import { configAccountAction } from './redux/actions/AccountActions';
import Loading from './components/loading/Loading';
import TermAndConditions from './pages/dynamics/TermAndConditions';
import GlobalContainer from './GlobalContainer';

class App extends Component {
  constructor(props) {
    super(props);
    this.props.userIsLogged();
    this.props.getAccountConfig();
    this.updateStyleVariables();
  }

  updateStyleVariables = () => {
    try {
      let root = document.documentElement;
      //
      if (window.style_config?.primary_background_color) {
        root.style.setProperty('--primary-background-color', window.style_config.primary_background_color);
      }
      if (window.style_config?.secondary_background_color) {
        root.style.setProperty('--secondary-background-color', window.style_config.secondary_background_color);
      }
      if (window.style_config?.text_color_with_secondary) {
        root.style.setProperty('--text-color-with-secondary', window.style_config.text_color_with_secondary);
      }
      //
      if (window.style_config?.button_offer_winning) {
        root.style.setProperty('--button-offer-winning', window.style_config.button_offer_winning);
      }
      if (window.style_config?.button_offer_not_auctioned) {
        root.style.setProperty('--button-offer-not_auctioned', window.style_config.button_offer_not_auctioned);
      }
      if (window.style_config?.button_offer_not_offered) {
        root.style.setProperty('--button-offer-not_offered', window.style_config.button_offer_not_offered);
      }
      if (window.style_config?.button_offer_auctioned) {
        root.style.setProperty('--button-offer-auctioned', window.style_config.button_offer_auctioned);
      }
      //
      if (window.style_config?.secondary_background_accordion_hover) {
        root.style.setProperty('--secondary-background-color-accordion-hover', window.style_config.secondary_background_accordion_hover);
      }
      //
    } catch (error) {

    }
  }

  createMeta = (content, name, property) => {
    let meta = document.createElement('meta');
    if (name !== null) {
      meta.name = name;
    }
    if (property !== null) {
      meta.property = property;
    }
    meta.content = content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.configAccount.config !== this.props.configAccount.config) {
      if (this.props.configAccount.config?.title_tabs !== null) {
        this.createMeta(
          this.props.configAccount.config.title_tabs,
          'description',
          null
        );
        this.createMeta(
          this.props.configAccount.config.title_tabs,
          'og:title',
          'og:title'
        );
        this.createMeta(
          this.props.configAccount.config.title_tabs,
          'twitter:card',
          null
        );
        this.createMeta(
          this.props.configAccount.config.title_tabs,
          null,
          'og:image'
        );
      }
      if (this.props.configAccount.config?.footer_company_web !== null) {
        let link = document.createElement('link');
        link.rel = 'canonical';
        link.href = this.props.configAccount.config.footer_company_web;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      if (this.props.configAccount.config?.analytic_code !== null) {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-TZ3N6HXFW0';
        script.async = true;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        if (window.gcode) {
          gtag('config', window.gcode);
        }
        gtag('config', this.props.configAccount.config.analytic_code);
      }
      if (this.props.configAccount.config?.favicon_web !== null) {
        let link = document.createElement('link');
        link.rel = 'icon';
        link.href =
          Constants.BASE_URL + this.props.configAccount.config.favicon_web;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.configAccount.loading && <Loading />}
        <BrowserRouter>
          <GlobalContainer>
            <Switch>
              <Route exact path='/subastas/:id/:y' component={AuctionDetails} />
              <Route exact path='/subastas/:id' component={AuctionDetails} />
              <Route exact path='/lotes/:id/:y' component={LotDetails} />
              <Route exact path='/lotes/:id' component={LotDetails} />
              <Route exact path='/contacto' component={Contact} />
              {/* DYNAMIC PAGES */}
              {this.props.configAccount.config?.include_page_faq && (
                <Route exact path='/ayuda' component={FAQ} />
              )}
              {this.props.configAccount.config?.include_page_buy && (
                <Route exact path='/comprar' component={Buy} />
              )}
              {this.props.configAccount.config?.include_page_sell && (
                <Route exact path='/vender' component={Sell} />
              )}
              {this.props.configAccount.config?.include_page_form_pay && (
                <Route exact path='/forma-de-pago' component={WayToPay} />
              )}
              {this.props.configAccount.config?.include_page_conditions && (
                <Route
                  exact
                  path='/terminos-condiciones'
                  component={TermAndConditions}
                />
              )}

              <Route exact path='/' component={HomePage} />
              {this.props.sessionProps.account === null && (
                <Route exact path='/ingresar' component={Login} />
              )}
              {this.props.sessionProps.account === null && (
                <Route
                  exact
                  path='/ingresar/:auction/:y/:segment'
                  component={Login}
                />
              )}
              {this.props.sessionProps.account === null && (
                <Route exact path='/registrar' component={Register} />
              )}
              {this.props.sessionProps.account !== null && (
                <Route
                  exact
                  path='/mi-cuenta/:tab/:auction/:page'
                  component={MyAccount}
                />
              )}
              {this.props.sessionProps.account !== null && (
                <Route exact path='/mi-cuenta' component={MyAccount} />
              )}
               <Route component={HomePage} />
            </Switch>
          </GlobalContainer>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  configAccount: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  userIsLogged: () => {
    dispatch(userIsLoggedAction());
  },
  getAccountConfig: () => {
    dispatch(configAccountAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
