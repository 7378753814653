import React, { Component } from 'react';
import { connect } from 'react-redux';
import GoogleMap from '../components/google-maps/GoogleMap';
import TitleBox from '../ui-components/title-box';
import ContactForm from '../components/contact';

class Contact extends Component {
  state = {
    title: 'Contacto'
  };

  componentDidMount() {
    let title = this.state.title;
    if (this.props.configAccount.config?.title_tabs) {
      title = this.props.configAccount.config.title_tabs + ' - ' + title;
    }
    document.title = title;
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.configAccount.config !== this.props.configAccount.config) {
      if (this.props.configAccount.config?.title_tabs !== null) {
        document.title =
          this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <section className='contact-area ptb-60'>
          <div className='container'>
            <TitleBox title='Contacto' />
            <div className="row" style={{ marginTop: '4rem' }}>
              <div className="col-md-6 col-12">
                <ContactForm />
              </div>
              <div className='col-md-6 col-12'>
                <div className='contact-info'>
                  <ul className='contact-list'>
                    <li style={{ fontSize: '16px' }}>
                      <i className='fas fa-map-marker-alt'></i>
                      {this.props.configAccount.config?.contact_address
                        ? this.props.configAccount.config.contact_address
                        : ''}
                    </li>
                    <li style={{ fontSize: '16px' }}>
                      <i className='fas fa-phone'></i>
                      {this.props.configAccount.config?.contact_phone && (
                        <a
                          href={`tel:${this.props.configAccount.config.contact_phone}`}
                        >
                          {this.props.configAccount.config.contact_phone}
                        </a>
                      )}
                    </li>
                    <li style={{ fontSize: '16px' }}>
                      <i className='far fa-envelope'></i>
                      {this.props.configAccount.config?.contact_email && (
                        <a
                          href={`mailto:${this.props.configAccount.config.contact_email}`}
                        >
                          {this.props.configAccount.config.contact_email}
                        </a>
                      )}
                    </li>
                  </ul>
                  {this.props.configAccount.config?.contact_map_lat && (
                    <GoogleMap
                      lat={this.props.configAccount.config.contact_map_lat}
                      lng={this.props.configAccount.config.contact_map_lng}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  configAccount: state.accountReducer,
});

export default connect(mapStateToProps, null)(Contact);
