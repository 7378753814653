import React from 'react';

const GoTop = () => {
    const [thePosition, setThePosition] = React.useState(false);

    React.useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                setThePosition(true)
            } else {
                setThePosition(false);
            }
        });
    }, [])

    const scrollToTop = () => {
        window.scroll(0, 0);
    }

    const renderGoTopIcon = () => {
        if (thePosition) {
            return (
                <div className={`go-top`} onClick={scrollToTop}>
                    <i className="fas fa-arrow-up"></i>
                    <i className="fas fa-arrow-up"></i>
                </div>
            )
        }
    }

    return (
        <React.Fragment>
            {renderGoTopIcon()}
        </React.Fragment>
    )
}

export default GoTop;