import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const ItemAuction = ({
  img,
  lotsCount,
  auctionNumber,
  dateFrom,
  dateTo,
  name,
}) => {
  return (
    <div className='position-relative item-auction'>
      <div className='image-cover'>
        <div className='content-box'>
          <span className='lots'>
            <b>{lotsCount}</b> lotes
          </span>
          <div className='content'>
            <h3>
              {name} #{auctionNumber}
            </h3>
            <div className='line'>
              <span>inicio</span> {dateFrom}
            </div>
            <div className='line'>
              <span>cierre</span> {dateTo}
            </div>
            <Link to={`subastas/${auctionNumber}`}>
              <button>Ir a la subasta</button>
            </Link>
          </div>
        </div>
      </div>
      <img alt='' src={img} />
    </div>
  );
};

export default ItemAuction;
