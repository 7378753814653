import React, { Component } from 'react';
import { getBanners } from '../../services/BannersServices';
import CarouselPhotos from '../carousel/CarouselPhotos';

class BannerSlider extends Component {
  state = {
    banners: [],
  };

  componentDidMount() {
    this.fetchBanners();
  }

  fetchBanners = async () => {
    try {
      let response = await getBanners();
      await this.setState({
        banners: response.data.data.banners,
      });
    } catch (error) { }
  };

  render() {
    return (
      <React.Fragment>
        <CarouselPhotos photos={this.state.banners} />
      </React.Fragment>
    );
  }
}

export default BannerSlider;
