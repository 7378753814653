import React from 'react';
import { Link } from 'react-router-dom';
import { Constants } from '../../../Constants';
import { useAccount } from '../../../store/account';
import GoTop from '../../Shared/GoTop';
import './styles.scss';

const Footer = () => {
  const accountStore = useAccount();

  return (
    <footer>
      <div className='footer-box container'>
        <div className='row'>
          <div className={`${window.socket_io_customer === 'luisina_veiga' ? 'text-center' : 'text-left'} col-12`}>
            <p style={{ marginBottom: '0px' }}>{accountStore.config?.footer_text_1 ? accountStore.config.footer_text_1 : ''}
              {accountStore.config?.footer_text_2 ? ' - ' + accountStore.config.footer_text_2 : ''}
              {accountStore.config?.footer_text_3 ? ' - ' + accountStore.config.footer_text_3 : ''}
              {accountStore.config?.footer_text_4 ? ' - ' + accountStore.config.footer_text_4 : ''}</p>
            <span>
              Copyright &copy; {new Date().getFullYear()}&nbsp;
              {
                accountStore.config?.footer_company_web &&
                <React.Fragment>
                  <a href={accountStore.config.footer_company_web} target="_blank">
                    {accountStore.config?.footer_company_name}
                  </a>.&nbsp;
                </React.Fragment>
              }
              All Rights Reserved By <a href="http://sayasoftware.com/" target="_blank">SayaSoftware</a> Versión {Constants.VERSION}
            </span>
          </div>
          {/*<div className='right-items col-md-4 col-sm-12'>
            <div className='icon fb'></div>
            <div className='icon ig'></div>
            <div className='icon wp'></div>
          </div>*/}
        </div>
      </div>
      <GoTop />
    </footer>
  );
};

export default Footer;
