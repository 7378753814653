import { useDispatch, useSelector } from 'react-redux';
import { gerUserLogin, loginWithCode, logout } from './actions';
import { session, dataLogin } from './selectors';

export function useUser() {
  const dispatch = useDispatch();

  return {
    gerUserLogin: () => dispatch(gerUserLogin()),
    loginWithCode: (code) => dispatch(loginWithCode(code)),
    logout: () => dispatch(logout()),
    session: useSelector(session),
    dataLogin: useSelector(dataLogin),
  };
}
