import React, { Component } from 'react';
import AuctionDetail from '../../components/auction/AuctionDetail';

class AuctionDetails extends Component {
  render() {
    return (
      <React.Fragment>
        <AuctionDetail {...this.props} />
      </React.Fragment>
    );
  }
}

export default AuctionDetails;
