import React, { Fragment } from 'react';
import Footer from './components/Layout/footer/index';
import Menu from './components/Layout/menu';
import AuctionInProgress from './ui-components/auction-inprogress';

const GlobalContainer = (props) => {
  return (
    <Fragment>
      <Menu />
      {/*<AuctionInProgress text1="Subasta" text2="en curso" />*/}
      <div>{props.children}</div>
      <Footer />
    </Fragment>
  );
};

export default GlobalContainer;
