import { Actions } from "../actions/Actions";

const initialState = {
    account: null
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.USER_LOGIN_SET_STORAGE:
            return { ...state, account: action.data.account };
        default:
            return state;
    }
}