import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { Constants } from '../../Constants';
import Loading from '../loading/Loading';
import { addOffer, deleteOffer } from '../../services/OffersServices';
import { toast } from 'react-toastify';
import './ProductContent.scss';
import { logoutAction } from '../../redux/actions/UserActions';
import { confirmAlert } from 'react-confirm-alert';

class ProductContent extends Component {
    state = {
        loading: false,
        manualOffer: this.props.nextOffer
    }

    componentDidMount = async () => {
        toast.configure();
        moment.locale('es');
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    handleChange = async (event) => {
        const { name, value } = event.target;
        let valor = parseInt(Math.round(value / 10) * 10);
        if (valor < this.props.nextOffer) {
            await this.setState({
                manualOffer: this.props.nextOffer
            });
        }
        else {
            await this.setState({
                manualOffer: valor
            });
        }
    }

    offer = () => {
        let answer = false;
        if (this.props.nextOffer > 1000) {
            if (this.state.manualOffer >= this.props.nextOffer + this.props.nextOffer * 1.60) {
                answer = true;
            }
        }
        else {
            if (this.state.manualOffer >= this.props.nextOffer * 2) {
                answer = true;
            }
        }
        if (answer) {
            confirmAlert({
                title: `¿Esta seguro que desea continuar?`,
                message: `Su oferta de ${this.props.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(this.state.manualOffer)} supera altamente la oferta actual`,
                buttons: [
                    {
                        label: 'Cancelar'
                    },
                    {
                        label: 'Confirmar',
                        onClick: () => {
                            this._offer()
                        }
                    }
                ]
            });
        }
        else {
            this._offer()
        }
    }

    _offer = async () => {
        await this.setState({ loading: true });
        try {
            let response = await addOffer({
                token: this.props.sessionProps.account.token,
                id_lot: this.props.id,
                offer: this.state.manualOffer > this.props.nextOffer ? this.state.manualOffer : this.props.nextOffer
            });
            await this.setState({ loading: false });
            toast("Tu oferta ha sido ingresada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.props.id}/0/lotes`);
            }
            else {
                toast.error("No fue posible ingresar tu oferta, reintentar nuevamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    deleteOffer = (item) => {
        confirmAlert({
            title: `Cancelar oferta`,
            message: `¿Esta seguro que desea cancelar la oferta?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deleteOffer(item)
                    }
                }
            ]
        });
    }

    _deleteOffer = async (item) => {
        await this.setState({ loading: true })
        try {
            let response = await deleteOffer({
                token: this.props.sessionProps.account.token,
                id: item.offerId,
                lot: item.id
            });
            await this.setState({ loading: false });
            toast(`La oferta ha sido cancelada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    countDownCancelOffer = ({ item }) => {
        try {
            if (this.props.sessionProps.account === null || this.props.sessionProps.account.id !== item.bestUserIdOffer) {
                return (<React.Fragment></React.Fragment>);
            }
            if (this.props.timestamp === null || item.timestampCancelOffer === null) {
                return (<React.Fragment></React.Fragment>);
            }
            //
            let difference = new Date(item.timestampCancelOffer * 1000).getTime() - new Date(this.props.timestamp * 1000).getTime();
            let seconds = Math.floor(difference / 1000);
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let days = Math.floor(hours / 24);
            hours %= 24;
            minutes %= 60;
            seconds %= 60;
            //
            if (days === -1 && hours === -1 && minutes === -1 && seconds + 30 <= 30 && seconds + 30 >= 0) {
                return (
                    <div style={{ textAlign: 'center', marginTop: '0.5rem' }}>
                        <button onClick={() => this.deleteOffer(item)} style={{ background: 'transparent', border: 'none', color: 'red', textDecoration: 'underline' }}>
                            {`Cancelar oferta (${seconds + 30})`}
                        </button>
                    </div>
                );
            }
            //
            return (<React.Fragment></React.Fragment>);
        } catch (error) {
            return (<React.Fragment></React.Fragment>);
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <div className="product-details-content">
                    <h2
                        style={{ background: `${window.socket_io_customer === 'humberto_soto_remates' ? 'black' : 'transparent'}`, padding: '1rem' }}>{this.props.title}</h2>
                    <div>
                        <label className="auction-title">SUBASTA:</label>
                        <label className="auction-content">{this.props.auction.title}</label>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: this.props.description }} className="description-lots" />

                    <ul className="product-info">
                        <li><span>Cantidad:</span> {this.props.quantity}</li>
                        <li><span>Cierre:</span> {` ${this.capitalize(moment(this.props.date_close).format('dddd D'))} de 
                                ${this.capitalize(moment(this.props.date_close).format('MMMM'))} hora ${moment(this.props.date_close).format('HH:mm')}`}</li>
                        <li><span>Lote:</span> {this.props.id_lot}</li>
                        <li><span>Precio base:</span>{this.props.price_base > 0 ?
                            ` ${this.props.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(this.props.price_base)}` : ' Sin precio base'}</li>
                        {
                            this.props.status === Constants.STATUS_IN_PROGRESS &&
                            <li><span>Oferta actual:</span>{` ${this.props.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(this.props.offer)}`}</li>
                        }
                        {
                            this.props.bestUserNameOffer &&
                            <li><span>Ganador actual:</span> {this.props.bestUserNameOffer}</li>
                        }
                    </ul>

                    <div className="buy-checkbox-btn">
                        {
                            this.props.status === Constants.STATUS_IN_PROGRESS ?
                                <React.Fragment>
                                    {
                                        this.props.sessionProps.account === null &&
                                        <Link to={`/ingresar/${this.props.id}/0/lotes`}>
                                            <button className={`btn btn-primary w-100 not_offered`}>
                                                INGRESAR PARA OFERTAR
                                            </button>
                                        </Link>
                                    }
                                    {
                                        (this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== this.props.bestUserIdOffer) ?
                                            this.props.participation ?
                                                <Link to="#">
                                                    <button className={`btn btn-primary w-100 not_offered`}
                                                        onClick={() => this.offer(this.props)}>
                                                        OFERTAR {` ${this.props.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(
                                                            this.state.manualOffer !== undefined && this.state.manualOffer > this.props.manualOffer ? this.state.manualOffer : this.props.manualOffer)}`}
                                                    </button>
                                                </Link>
                                                :
                                                <Link to="#">
                                                    <button className={`btn btn-primary w-100 not_offered`}
                                                        onClick={() => this.offer(this.props)}>
                                                        OFERTAR {` ${this.props.auction.moneySymbol} ${new Intl.NumberFormat("de-DE").format(
                                                            this.state.manualOffer !== undefined && this.state.manualOffer > this.props.manualOffer ? this.state.manualOffer : this.props.manualOffer)}`}
                                                    </button>
                                                </Link>
                                            : null
                                    }
                                    {
                                        this.props.sessionProps.account !== null && this.props.sessionProps.account.id === this.props.bestUserIdOffer &&
                                        <Link to="#">
                                            <button className="btn btn-primary w-100 winning" disabled={true}>
                                                <i className="fas fa-star"></i> MEJOR OFERTA
                                            </button>
                                        </Link>
                                    }
                                    {
                                        this.props.sessionProps.account !== null && this.props.sessionProps.account.id !== this.props.bestUserIdOffer &&
                                        <div className="item text-center" style={{ marginTop: '5px' }}>
                                            <div className="product-add-to-cart">
                                                <div className="input-counter" style={{ width: '100%', maxWidth: '100%' }} >
                                                    <input type="number" min={this.state.nextOffer} step="10" placeholder="Ingresar monto manual" style={{ width: '100%', textAlign: 'center' }}
                                                        onChange={(event) => this.handleChange(event)} />
                                                </div>
                                            </div>
                                        </div>

                                    }
                                    {
                                        this.countDownCancelOffer({ item: this.props })
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {
                                        this.props.status === Constants.STATUS_PENDING || this.props.status === Constants.STATUS_DELIVERED ?
                                            <React.Fragment>
                                                {
                                                    this.props.sessionProps.account !== null && this.props.sessionProps.account.id === this.props.bestUserIdOffer ?
                                                        <Link to="#">
                                                            <button className="btn btn-primary w-100 winning" disabled={true}>
                                                                <i className="fas fa-star"></i> SUBASTADO {this.props.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(this.props.offer)}
                                                            </button>
                                                        </Link>
                                                        :
                                                        <Link to="#" className="noHover">
                                                            <button className="btn btn-primary w-100 auctioned" disabled={true}>
                                                                SUBASTADO {this.props.auction.moneySymbol} {new Intl.NumberFormat("de-DE").format(this.props.offer)}
                                                            </button>
                                                        </Link>
                                                }
                                            </React.Fragment>
                                            : null
                                    }
                                    {
                                        this.props.status === Constants.STATUS_NOT_AUCTIONED &&
                                        <Link to="#" className="noHover">
                                            <button className="btn btn-primary w-100 not_auctioned" disabled={true}>
                                                NO SUBASTADO
                                            </button>
                                        </Link>
                                    }
                                </React.Fragment>
                        }
                        <div className="item text-center">
                            <Link to="/ayuda">
                                <span className="go-to-faq">
                                    Accedé a nuestras Preguntas Frecuentes
                                </span>
                            </Link>
                        </div>
                    </div>

                </div>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductContent);