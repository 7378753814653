import axios from 'axios';
import { Constants } from '../Constants';
import { logoutAction } from '../redux/actions/UserActions';

export const addOffer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}offers`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteOffer = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}offers/public/delete`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}