import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './redux/reducers';
//
import './assets/styles/bootstrap.min.css';
import './assets/styles/fontawesome.min.css';
import './styles.scss';
//
import 'react-image-lightbox/style.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const store = createStore(
  reducers, // Reducers
  {},
  applyMiddleware(reduxThunk)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
