import React, { Component } from 'react';
import { Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux'
import { logoutAction } from '../../redux/actions/UserActions';
import { Tabs, Tab } from 'react-bootstrap';
import './MyAccount.scss';
import {
    getMyShoppings, getMyOffers, getMyAccount, departaments, updateMyAccount, verificarCI,
    verificarRUT, getLotsForLiquidate
} from '../../services/UserServices';
import Loading from '../../components/loading/Loading';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Constants } from '../../Constants';
import { addOffer } from '../../services/OffersServices';
import TitleBox from '../../ui-components/title-box';

class MyAccount extends Component {
    state = {
        loading: false,
        tab: 'myAccount',
        shoppings: [],
        offers: [],
        account: null,
        departaments: [],
        messageError: '',
        departamentSelect: 16,
        fb: null,
        type_document: Constants.TYPE_DOCUMENT_CI,
        interval: null,
        auctions: [
            {
                id: 0,
                name: 'Filtrar por remate'
            }
        ],
        auction: 0,
        externalAuction: null,
        externalPage: null,
        totals: {
            total: 0,
            totalToPay: 0
        },
        liquidationFilter: "1",
        totalsLiquidation: {
            total: 0,
            subtotal: 0,
            iva: 0,
            comision: 0,
            municipal_tax: 0
        },
        moneySymbol: '$',
        title: 'Mi cuenta',
    }

    componentDidMount = async () => {
        let title = this.state.title;
        if (this.props.configAccount.config?.title_tabs) {
            title = this.props.configAccount.config.title_tabs + ' - ' + title;
        }
        document.title = title;
        toast.configure();
        if (this.props.match.params.tab !== undefined) {
            await this.setState({
                externalAuction: parseInt(this.props.match.params.auction),
                externalPage: this.props.match.params.page
            });
            switch (parseInt(this.props.match.params.tab)) {
                case 1:
                    this.changeTab('myAccount', undefined);
                    break;
                case 2:
                    this.changeTab('myOffers', undefined);
                    break;
                case 3:
                    this.changeTab('myShopping', undefined);
                    break;
                default:
                    this.changeTab('myAccount', undefined);
                    break;
            }
        }
        else {
            this.changeTab('myAccount', undefined);
        }
        this.fetchDepartamens();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.configAccount.config !== this.props.configAccount.config) {
            if (this.props.configAccount.config?.title_tabs !== null) {
                document.title = this.props.configAccount.config.title_tabs + ' - ' + this.state.title;
            }
        }
    }

    changeTab = async (tab, e) => {
        if (e !== undefined && e.type !== "click") {
            return;
        }
        this.clearInterval();
        if (tab === 'myShopping') {
            this.fetchShoppings();
        }
        if (tab === 'myOffers') {
            this.fetchOffers();
            let interval = setInterval(() => {
                this.fetchOffers(false);
            }, 4000);
            await this.setState({ interval: interval });
        }
        if (tab === 'myAccount') {
            this.fetchMyAccount();
        }
        await this.setState({ tab: tab });
    }

    componentWillUnmount = () => {
        this.clearInterval();
    }

    clearInterval = () => {
        this.state.interval !== null && clearInterval(this.state.interval);
    }

    fetchShoppings = async () => {
        await this.setState({ loading: true })
        try {
            let response = await getMyShoppings({
                token: this.props.sessionProps.account.token,
                page: 1,
                auction: this.state.auction
            });
            let shoppings = response.data.data.lots;
            shoppings.sort(function (a, b) {
                if (a.auction.date_from > b.auction.date_from) return -1;
                if (a.auction.date_from < b.auction.date_from) return 1;
                return 0;
            });
            let auctions = this.state.auctions;
            let total = 0;
            let totalToPay = 0;
            shoppings.map(item => {
                total += parseFloat(item.offer.replace('.', ''));
                totalToPay += parseFloat(item.totalPagar.replace('.', ''));
                let _item = { id: item.auction.id, name: item.auction.title };
                if (auctions.filter(auction => auction.id === _item.id).length === 0) {
                    auctions.push(_item);
                }
            });
            if (this.state.auction === 0 && auctions.length > 1) {
                await this.setState({
                    auction: auctions[1].id
                })
                this.fetchShoppings();
                return;
            }
            await this.setState({
                shoppings: shoppings,
                loading: false,
                auctions: auctions,
                totals: {
                    total: total,
                    totalToPay: totalToPay
                }
            });
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
        }
    }

    fetchOffers = async (showSpinner = true) => {
        showSpinner && await this.setState({ loading: true })
        try {
            let response = await getMyOffers({
                token: this.props.sessionProps.account.token,
                page: 1
            });
            await this.setState({
                offers: response.data.data.lots,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
        }
    }

    fetchMyAccount = async () => {
        await this.setState({ loading: true })
        try {
            let response = await getMyAccount({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                ...response.data.data.user,
                password: '',
                confirmPassword: '',
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
        }
    }

    fetchDepartamens = async () => {
        try {
            let response = await departaments();
            await this.setState({ departaments: response.data.data.departaments });
        } catch (error) {

        }
    }

    handleChange = (event, maxLength = null) => {
        const { name, value } = event.target;
        this.setState({
            [name]: maxLength ? value.slice(0, this.getMaxLength()) : value
        });
    }

    handleChangeAuction = async (event) => {
        await this.setState({
            auction: event.target.value
        });
        this.fetchShoppings();
    }

    saveAccount = async (e) => {
        e.preventDefault();
        await this.setState({ loading: true })
        if (this.state.name.match(/(\w+)/g).length < 2) {
            await this.setState({ messageError: 'El nombre debe tener al menos 2 palabras', loading: false });
            return;
        }
        if (this.state.password !== '' && this.state.password !== this.state.confirmPassword) {
            await this.setState({ messageError: 'Las contraseñas no coinciden', loading: false });
            return;
        }
        if (this.state.password !== '' && this.state.password.length < 6) {
            await this.setState({ messageError: 'La contraseña debe contener al menos 6 caracteres', loading: false });
            return;
        }
        if (this.state.type_document === Constants.TYPE_DOCUMENT_CI) {
            let isValid = await verificarCI(this.state.document);
            if (!isValid) {
                await this.setState({ messageError: 'La CI ingresada no tiene un formato válido', loading: false });
                return;
            }
        }
        if (this.state.type_document === Constants.TYPE_DOCUMENT_RUT) {
            let isValid = await verificarRUT(this.state.document);
            if (!isValid) {
                await this.setState({ messageError: 'El RUT ingresado no tiene un formato válido', loading: false });
                return;
            }
        }
        let account = {
            token: this.props.sessionProps.account.token,
            address: this.state.address,
            document: this.state.document,
            id_departament: this.state.departamentSelect,
            name: this.state.name,
            phone: this.state.phone,
            telephone: this.state.telephone,
            type_document: this.state.type_document,
            city: this.state.city,
            email: this.state.email
        };
        if (this.state.password !== '') {
            account.password = this.state.password;
        }
        try {
            let response = await updateMyAccount(account);
            toast("Su cuenta fue actualizada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            window.scrollTo(0, 0);
            this.fetchMyAccount();
            await this.setState({ loading: false, messageError: '' })
        } catch (error) {
            await this.setState({ loading: false })
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/ingresar');
            }
            else {
                let msg = 'Ocurrió un error al procesar la solicitud, reintentar';
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                    msg = error.response.data.message;
                }
                toast.error(msg, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    getMaxLength = () => {
        if (this.state.type_document == Constants.TYPE_DOCUMENT_CI) {
            return 8;
        }
        if (this.state.type_document == Constants.TYPE_DOCUMENT_RUT) {
            return 12;
        }
        return 50;
    }

    logout = () => {
        this.props.logout();
        this.props.history.push('/');
    }

    offer = (item) => {
        this._offer(item)
    }

    _offer = async (item) => {
        await this.setState({ loading: true });
        try {
            let response = await addOffer({
                token: this.props.sessionProps.account.token,
                id_lot: item.id,
                offer: item.nextOffer
            });
            this.fetchOffers(true);
            await this.setState({ loading: false });
            toast("Tu oferta ha sido ingresada con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error.response.status === 401) {
                this.props.logout();
                this.props.history.push(`/ingresar/${this.state.id}/${this.state.positionY}/subastas`);
            }
            else {
                toast.error("No fue posible ingresar tu oferta, reintentar nuevamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loading && <Loading />}
                <section className="ptb-60">
                    <div className="container">
                        <div className='mb-5'>
                            <TitleBox title='Mi cuenta' />
                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4>Bienvenid@ {this.props.sessionProps.account.name}</h4>
                                    <p>
                                        Desde tu cuenta podrás modificar los detalles de tu cuenta, ver tus ofertas activas y tus compras.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '2rem' }}>
                            <div className="row">
                                {
                                    this.state.externalAuction !== null && this.state.externalPage !== null &&
                                    <div className="col-12 text-left" style={{ marginBottom: '0.5rem' }}>
                                        <Link to={`/${this.state.externalPage}/${this.state.externalAuction}`}
                                            style={{ cursor: "pointer", textDecoration: 'none', fontSize: '1rem', color: 'var(--primary-background-color)' }}>
                                            <i className="fas fa-arrow-left"></i>
                                            <span> Volver al listado</span>
                                        </Link>
                                    </div>
                                }
                                <div className="col-12">
                                    <Tabs activeKey={this.state.tab} onSelect={(k, e) => this.changeTab(k, e)}>
                                        <Tab eventKey="myAccount" tabClassName="nav-tabs-custom" title={<span> <i className="fas fa-user"></i> Mi cuenta</span>}>
                                            <form className="signup-form" onSubmit={this.saveAccount} style={{ marginTop: '2rem' }}>
                                                <div className="row">

                                                    {
                                                        this.state.login_code && this.state.login_code !== '' &&
                                                        <div className="col-12 col-md-6 form-group">
                                                            <label>Código de ingreso</label>
                                                            <input type="text" className="form-control" name="loginCode"
                                                                value={this.state.login_code} disabled />
                                                        </div>
                                                    }

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Email <b style={{ color: 'red' }}>*</b></label>
                                                        <input type="email" className="form-control" placeholder="Ingrese su email" name="email"
                                                            value={this.state.email} onChange={this.handleChange} required />
                                                    </div>

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Nombre Completo <b style={{ color: 'red' }}>*</b></label>
                                                        <input type="text" className="form-control" placeholder="Ingrese su nombre completo" name="name"
                                                            value={this.state.name} onChange={this.handleChange} required disabled />
                                                    </div>

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Celular <b style={{ color: 'red' }}>*</b></label>
                                                        <input type="text" className="form-control" placeholder="Ingrese su celular" name="phone"
                                                            value={this.state.phone} onChange={this.handleChange} required />
                                                    </div>

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Teléfono</label>
                                                        <input type="text" className="form-control" placeholder="Ingresar su teléfono" name="telephone"
                                                            value={this.state.telephone} onChange={this.handleChange} />
                                                    </div>

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Documento</label>
                                                        <div style={{ display: 'flex' }}>
                                                            <select className="form-control" name="type_document" onChange={this.handleChange} style={{ width: 'max-content' }}>
                                                                <option value={Constants.TYPE_DOCUMENT_CI}
                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_CI ? true : false}>CI</option>
                                                                <option value={Constants.TYPE_DOCUMENT_RUT}
                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_RUT ? true : false}>RUT</option>
                                                                <option value={Constants.TYPE_DOCUMENT_OTHER}
                                                                    selected={this.state.type_document === Constants.TYPE_DOCUMENT_OTHER ? true : false}>Otro</option>
                                                            </select>
                                                            <input type="number" className="form-control" placeholder="Ingrese su documento" name="document"
                                                                value={this.state.document} onChange={(e) => this.handleChange(e, true)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Dirección <b style={{ color: 'red' }}>*</b></label>
                                                        <input type="text" className="form-control" placeholder="Ingrese su dirección" name="address"
                                                            value={this.state.address} onChange={this.handleChange} required />
                                                    </div>

                                                    <div className="col-12 col-md-6 form-group">
                                                        <label>Ciudad <b style={{ color: 'red' }}>*</b></label>
                                                        <input type="text" className="form-control" placeholder="Ingrese su ciudad" name="city"
                                                            value={this.state.city} onChange={this.handleChange} required />
                                                    </div>

                                                    {
                                                        this.props.configAccount.config?.show_department ?
                                                            <div className="col-12 col-md-6 form-group">
                                                                <label>Departamento <b style={{ color: 'red' }}>*</b></label>
                                                                <div className="select-box">
                                                                    <select className="form-control" name="departamentSelect" onChange={this.handleChange}>
                                                                        {
                                                                            this.state.departaments.map(item =>
                                                                                <option value={item.id} selected={item.id === 16 ? true : false}>{item.name}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div> : null
                                                    }

                                                    {
                                                        !this.state.fb &&
                                                        <React.Fragment>
                                                            <div className="col-12 col-md-6 form-group">
                                                                <label>Nueva Contraseña</label>
                                                                <input type="password" className="form-control" placeholder="Ingrese su nueva contraseña" name="password"
                                                                    value={this.state.password} onChange={this.handleChange} min="6" />
                                                            </div>

                                                            <div className="col-12 col-md-6 form-group">
                                                                <label>Confirmar Nueva Contraseña</label>
                                                                <input type="password" className="form-control" placeholder="Ingrese la confirmación de su nueva contraseña" name="confirmPassword"
                                                                    value={this.state.confirmPassword} onChange={this.handleChange} min="6" />
                                                            </div>
                                                        </React.Fragment>
                                                    }

                                                    <div className="col-12 col-md-6">
                                                        <div className="buttons" style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                                                            <div>
                                                                <button type="submit" className="btn button-with-degrade">Guardar cambios</button>
                                                            </div>
                                                            <div className="ml-auto">
                                                                <Link onClick={() => this.logout()} className="return-store">
                                                                    Cerrar Sesión
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ textAlign: 'center', color: 'red' }}>
                                                        <label>{this.state.messageError}</label>
                                                    </div>

                                                </div>
                                            </form>
                                        </Tab>
                                        <Tab eventKey="myOffers" tabClassName="nav-tabs-custom" title={<span> <i className="fas fa-gavel"></i>  Mis ofertas</span>}>
                                            <Table striped bordered hover style={{ marginTop: '2rem' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Subasta</th>
                                                        <th>Mi oferta</th>
                                                        <th>Estado</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.offers.map(item =>
                                                            <tr>
                                                                <td>{item.id_lot}</td>
                                                                <td>{item.title}</td>
                                                                <td>
                                                                    <a href={`/subastas/${item.auction.id}`} target="_blank">
                                                                        {item.auction.title}
                                                                    </a>
                                                                </td>
                                                                <td>{item.moneySymbol} {item.offer}</td>
                                                                {
                                                                    item.win ?
                                                                        <td>¡Ganando!</td>
                                                                        : <td style={{ display: 'flex', alignItems: 'center' }}>
                                                                            ¡Perdiendo! Mejor oferta: {item.moneySymbol} {item.best_offer}.
                                                                            <Link to="#" style={{ marginLeft: '.5rem' }}>
                                                                                <button className={`btn btn-primary btn-mobile losing`} onClick={() => this.offer(item)}
                                                                                    style={{ padding: '6px 12px', fontSize: '12px' }}>
                                                                                    OFERTAR {` ${item.moneySymbol} ${new Intl.NumberFormat("de-DE").format(item.nextOffer)}`}
                                                                                </button>
                                                                            </Link>
                                                                        </td>
                                                                }
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <a href={`/lotes/${item.id}`} target="_blank">
                                                                        <i className="fas fa-search"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>

                                        </Tab>
                                        <Tab eventKey="myShopping" tabClassName="nav-tabs-custom" title={<span> <i className="fas fa-shopping-basket"></i>  Mis Compras</span>}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group" style={{ marginTop: '1rem' }}>
                                                        <div className="select-box">
                                                            <label style={{ color: 'grey', marginBottom: '0rem', fontSize: '14px' }}>Buscá tus compras por remate:</label>
                                                            <select className="form-control" name="auction" onChange={this.handleChangeAuction}>
                                                                {
                                                                    this.state.auctions.map(item =>
                                                                        <option value={item.id} selected={item.id === this.state.auction}>{item.name}</option>
                                                                    )
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Subasta</th>
                                                        <th>Precio subastado</th>
                                                        <th>Total a pagar</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.shoppings.map(item =>
                                                            <tr>
                                                                <td>{item.id_lot}</td>
                                                                <td>{item.title}</td>
                                                                <td>
                                                                    <a href={`/subastas/${item.auction.id}`} target="_blank">
                                                                        {item.auction.title}
                                                                    </a>
                                                                </td>
                                                                <td>{item.auction.moneySymbol} {item.offer}</td>
                                                                <td>{item.auction.moneySymbol} {item.totalPagar}</td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    <a href={`/lotes/${item.id}`} target="_blank">
                                                                        <i className="fas fa-search"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                                {
                                                    this.state.auction != 0 && this.state.shoppings.length > 0 &&
                                                    <tfoot>
                                                        <tr>
                                                            <th></th>
                                                            <th></th>
                                                            <th>Totales</th>
                                                            <th>{this.state.shoppings[0].auction.moneySymbol} {this.state.totals.total}</th>
                                                            <th>{this.state.shoppings[0].auction.moneySymbol} {this.state.totals.totalToPay}</th>
                                                            <th></th>
                                                        </tr>
                                                    </tfoot>
                                                }
                                            </Table>
                                            {
                                                this.state.auction != 0 && this.state.shoppings.length > 0 &&
                                                <div style={{ color: 'black' }}>
                                                    {this.state.shoppings[0].auction.info_aditional}
                                                </div>
                                            }
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => {
        dispatch(logoutAction());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
