export const Constants = {
    BASE_URL: window.api_url,
    BASE_URL_IMAGES: 'https://api.sayasoftware.com/v2cdn/',
    GENERIC_ERROR: 'Ocurrió un error al procesar la información',
    HASH_USER_SESSION: 'z8fynsq4vDsCKkEMXj6ZPA67xX2sYdBA',
    KEY_USER_SESSION: '5zxjyVTLX5ygvejQjkvRBsMPxG3FUCRR',
    STATUS_INITIAL: 1,
    STATUS_DELIVERED: 3,
    STATUS_PENDING: 2,
    STATUS_IN_PROGRESS: 5,
    STATUS_NOT_AUCTIONED: 4,
    VERSION: `2.3.0.2${window.api_url.includes('test') ? 'TESTING' : ''}`,
    TYPE_DOCUMENT_CI: 3,
    TYPE_DOCUMENT_RUT: 2,
    TYPE_DOCUMENT_OTHER: 4
};