import React, { useState } from 'react';
import Loading from '../loading/Loading';
import { toast } from 'react-toastify';
import './styles.scss';
import { useAccount } from '../../store/account';

const ContactForm = () => {
  const accountStore = useAccount();

  const [contact, setContact] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  const handleFieldChange = (field, value) => {
    setContact({
      ...contact,
      [field]: value,
    });
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    window.Email.send({
      SecureToken: '7073df93-8259-490e-9d43-9b869f755fc4',
      To: accountStore.config?.contact_email
        ? accountStore.config?.contact_email
        : 'contacto.sayasoftware@gmail.com',
      From: contact.email,
      Subject: 'Contacto desde la web',
      Body: `${contact.name} ha enviado un mensaje. <br>
            Su email es ${contact.email} y su número de teléfono es ${contact.phone}. <br>
            El mensaje es el siguiente: <br><br> ${contact.message}`,
    })
      .then(async (message) => {
        setLoading(false);
        let alertText = 'Ocurrió un error, reitentar';

        if (message === 'OK') {
          alertText =
            'Mensaje enviado con éxito, en breve nos pondremos en contacto';
        }
        toast(alertText, {
          position: toast.POSITION.BOTTOM_CENTER,
          pauseOnHover: false,
          duration: 10000,
        });
      })
      .catch(async (error) => {
        setLoading(false);
      });
  };

  return (
    <div className='contact-form'>
      {loading && <Loading />}
      <form onSubmit={(e) => handleOnSubmit(e)}>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              <input
                type='text'
                name='name'
                className='form-control'
                required={true}
                placeholder='Nombre Completo'
                value={contact.name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
              />
              <div className='help-block with-errors'></div>
            </div>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              <input
                type='email'
                name='email'
                className='form-control'
                required={true}
                placeholder='Mail'
                value={contact.email}
                onChange={(e) => handleFieldChange('email', e.target.value)}
              />
              <div className='help-block with-errors'></div>
            </div>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              <input
                type='text'
                name='phone'
                className='form-control'
                required={true}
                placeholder='Teléfono'
                value={contact.phone}
                onChange={(e) => handleFieldChange('phone', e.target.value)}
              />
              <div className='help-block with-errors'></div>
            </div>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='form-group'>
              <textarea
                name='message'
                cols='30'
                rows='8'
                required={true}
                className='form-control'
                placeholder='Mensaje'
                value={contact.message}
                onChange={(e) => handleFieldChange('message', e.target.value)}
              />
              <div className='help-block with-errors'></div>
            </div>
          </div>

          <div className='col-lg-12 col-md-12 text-center'>
            <button type='submit' className=''>
              Enviar
            </button>
            <div id='msgSubmit' className='h3 text-center hidden'></div>
            <div className='clearfix'></div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
