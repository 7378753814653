import { useDispatch, useSelector } from 'react-redux';
import { getAllAuctions } from './actions';
import { auctions } from './selectors';

export function useAuction() {
  const dispatch = useDispatch();

  return {
    getAllAuctions: () => dispatch(getAllAuctions()),
    auctions: useSelector(auctions),
  };
}
