import { handleActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import {
  GET_ALL_AUCTION_FAILURE,
  GET_ALL_AUCTION_LOADING,
  GET_ALL_AUCTION_SUCCESS,
} from '../action-types/auction';

const initState = {
  auctions: null,
};

const getAllAuctions = handleActions(
  {
    [GET_ALL_AUCTION_LOADING]: (state) => ({
      ...state,
      auctions: null,
    }),
    [GET_ALL_AUCTION_SUCCESS]: (state, action) => ({
      ...state,
      auctions: action.payload,
    }),
    [GET_ALL_AUCTION_FAILURE]: (state) => ({
      ...state,
      auctions: null,
    }),
  },
  initState
);

export default reduceReducers(getAllAuctions);
