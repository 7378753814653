import { useDispatch, useSelector } from 'react-redux';
import { getAccountConfig } from './actions';
import { config } from './selectors';

export function useAccount() {
  const dispatch = useDispatch();

  return {
    getAccountConfig: () => dispatch(getAccountConfig()),
    config: useSelector(config),
  };
}
