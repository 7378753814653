import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const ItemAuctionBig = ({
  img,
  lotsCount,
  auctionNumber,
  dateFrom,
  dateTo,
  name,
}) => {
  return (
    <div className='row inprogress-auction'>
      <div className='col-md-7 col-sm-12'>
        <div className='big-img-box'>
          <span>
            <b>{lotsCount}</b> lotes
          </span>
          <img alt='' src={img} />
        </div>
      </div>
      <div className='col-md-5 col-sm-12 text-box'>
        <h3>
          {name} #{auctionNumber}
        </h3>
        <div className='date-box'>
          <div className='icon'></div>
          <div className='dates'>
            <div className='item'>
              <div>inicio </div>
              {dateFrom}
            </div>
            <div className='item'>
              <div>cierre</div>
              {dateTo}
            </div>
          </div>
        </div>
        <Link to={`subastas/${auctionNumber}`}>
          <button>participá ahora</button>
        </Link>
      </div>
    </div>
  );
};

export default ItemAuctionBig;
