import { combineReducers } from 'redux';
import { userReducer } from './UserReducers';
import { accountReducer } from './AccountReducers';

//NEW
import auctionReducer from '../../store/auction/reducer';
import usersReducer from '../../store/user/reducer';

export default combineReducers({
  userReducer,
  accountReducer,
  auctionReducer,
  usersReducer,
});
