import { Actions } from "../actions/Actions";

const initialState = {
    config: null
}

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_ACCOUNT_CONFIG_LOADING:
            return { ...state, loading: true, config: null };
        case Actions.GET_ACCOUNT_CONFIG_SUCCESS:
            return { ...state, config: action.data.config, loading: false };
        default:
            return state;
    }
}