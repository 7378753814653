import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CountLots extends Component {
    state = {
        styles: {}
    };

    componentDidMount = async () => {
        if (this.props.left) {
            await this.setState({
                styles: {
                    position: 'absolute', left: '0', background: 'var(--secondary-background-color)', color: 'var(--text-color-with-secondary)',
                    padding: '0rem', margin: '0rem', height: 'max-content', zIndex: '1'
                }
            });
        }
        else {
            await this.setState({
                styles: {
                    position: 'absolute', right: '0', background: 'var(--secondary-background-color)', color: 'var(--text-color-with-secondary)',
                    padding: '0rem', margin: '0rem', height: 'max-content', zIndex: '1'
                }
            });
        }
    }

    render() {
        return (
            <div style={this.state.styles}>
                <label style={{ margin: '0px', fontSize: '12px', fontWeight: 'bold', padding: '4px 8px', color: 'var(--text-color-with-secondary)' }}>
                    {this.props.text}
                </label>
            </div>
        );
    }
}

export default CountLots;