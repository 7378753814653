import { handleActions } from 'redux-actions';
import reduceReducers from 'reduce-reducers';

import {
  USER_LOGIN_SET_STORAGE_SUCCESS,
  USER_LOGIN_SET_STORAGE_FAILURE,
  USER_LOGIN_SET_STORAGE_LOADING,
  LOGIN_WITH_CODE_FAILURE,
  LOGIN_WITH_CODE_LOADING,
  LOGIN_WITH_CODE_SUCCESS,
} from '../action-types/user';

const initState = {
  session: null,
  dataLogin: null,
};

const getUserSession = handleActions(
  {
    [USER_LOGIN_SET_STORAGE_LOADING]: (state) => ({
      ...state,
      session: null,
    }),
    [USER_LOGIN_SET_STORAGE_SUCCESS]: (state, action) => ({
      ...state,
      session: action.payload,
    }),
    [USER_LOGIN_SET_STORAGE_FAILURE]: (state) => ({
      ...state,
      session: null,
    }),
  },
  initState
);

const loginWithCode = handleActions(
  {
    [LOGIN_WITH_CODE_LOADING]: (state) => ({
      ...state,
      dataLogin: null,
    }),
    [LOGIN_WITH_CODE_SUCCESS]: (state, action) => ({
      ...state,
      dataLogin: action.payload,
    }),
    [LOGIN_WITH_CODE_FAILURE]: (state) => ({
      ...state,
      dataLogin: null,
    }),
  },
  initState
);

export default reduceReducers(getUserSession, loginWithCode);
