import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';

import './CarouselPhotos.scss';
import { Constants } from '../../Constants';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class CarouselPhotos extends Component {

  scrollToAuctionsList = () => {
    document.getElementById('auctions-list').scrollIntoView();
  }

  render() {
    return (
      <Carousel indicators={false} className='carousel-home'>
        {
          this.props.sessionProps.account === null ?
            <Carousel.Item interval={5000}>
              <Link to={`/ingresar`}>
                <img
                  className='d-block w-100'
                  src={window.url_principal_banner_register}
                />
              </Link>
            </Carousel.Item>
            :
            <Carousel.Item interval={5000}>
              <Link onClick={() => this.scrollToAuctionsList()}>
                <img
                  className='d-block w-100'
                  src={window.url_principal_banner}
                />
              </Link>
            </Carousel.Item>
        }
        {this.props.photos.map((photo) => (
          <Carousel.Item interval={5000}>
            {photo.id_remate !== null ? (
              <Link to={`/subastas/${photo.id_remate}`}>
                <img
                  className='d-block w-100'
                  src={`${Constants.BASE_URL}${photo.image}`}
                />
              </Link>
            ) : (
              <img
                className='d-block w-100'
                src={`${Constants.BASE_URL}${photo.image}`}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
});

export default connect(mapStateToProps, null)(CarouselPhotos);